var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    [
      _c("masthead", {
        attrs: { title: "Shared Settings", "show-search": false },
      }),
      _c(
        "div",
        { staticClass: "container-fluid pt-4 ml-0 pl-0 pr-0" },
        [
          _c(
            "b-tabs",
            {
              model: {
                value: _vm.currentTab,
                callback: function ($$v) {
                  _vm.currentTab = $$v
                },
                expression: "currentTab",
              },
            },
            [
              _c(
                "b-tab",
                { attrs: { title: "Shared with Others" } },
                [
                  _c("ajax-table", {
                    ref: "sharedWithOthersTable",
                    attrs: { "table-definition": _vm.tableSharedWithOthers },
                    scopedSlots: _vm._u([
                      {
                        key: "cell(actions)",
                        fn: function (row) {
                          return [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-modal",
                                    rawName: "v-b-modal.accessManagementModal",
                                    modifiers: { accessManagementModal: true },
                                  },
                                ],
                                staticClass: "ml-4 mr-4 pt-0 pb-0",
                                attrs: { variant: "link" },
                                on: {
                                  click: function ($event) {
                                    _vm.modalData = row
                                  },
                                },
                              },
                              [_vm._v("\n              Manage\n            ")]
                            ),
                            _c(
                              "b-button",
                              {
                                staticClass: "ml-4 mr-4 pt-0 pb-0",
                                staticStyle: { color: "red" },
                                attrs: { variant: "link" },
                                on: {
                                  click: function ($event) {
                                    return _vm.confirmRemove(row.item)
                                  },
                                },
                              },
                              [_vm._v("\n              Remove\n            ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "b-tab",
                { attrs: { title: "Shared with You" } },
                [
                  _c("ajax-table", {
                    ref: "sharedWithYouTable",
                    attrs: { "table-definition": _vm.tableSharedWithYou },
                    scopedSlots: _vm._u([
                      {
                        key: "cell(actions)",
                        fn: function (row) {
                          return [
                            _c(
                              "b-button",
                              {
                                staticClass: "ml-1 mr-1 pt-0 pb-0",
                                staticStyle: { color: "red" },
                                attrs: { variant: "link" },
                                on: {
                                  click: function ($event) {
                                    return _vm.confirmRemove(row.item)
                                  },
                                },
                              },
                              [_vm._v("\n              Leave\n            ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("access-management-modal", { attrs: { datasource: _vm.modalData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }