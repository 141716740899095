var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "accessManagementModal",
      attrs: {
        id: "accessManagementModal",
        title: _vm.modalTitle,
        "footer-class": "border-top-0",
        size: "lg",
      },
      on: { ok: _vm.handleOk },
    },
    [
      _vm.datasource && _vm.datasource.item
        ? _c(
            "b-form",
            { staticClass: "mb-0 pb-0", attrs: { autocomplete: "false" } },
            [
              _c(
                "b-card",
                { staticClass: "mb-4 pb-0" },
                [
                  _c("template", { slot: "header" }, [
                    _vm._v("\n        Permissions\n      "),
                  ]),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "companiesLabel",
                        "label-for": "companies",
                        label:
                          "Please select the role that should be applied to this user.",
                      },
                    },
                    [
                      _c("b-form-radio-group", {
                        attrs: {
                          options: _vm.roleOptions,
                          name: "radio-inline",
                        },
                        model: {
                          value: _vm.datasource.item.role,
                          callback: function ($$v) {
                            _vm.$set(_vm.datasource.item, "role", _vm._n($$v))
                          },
                          expression: "datasource.item.role",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c(
                "b-card",
                { staticClass: "mb-4 pb-0" },
                [
                  _c("span", { attrs: { slot: "header" }, slot: "header" }, [
                    _vm._v("Notifications"),
                  ]),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "companiesLabel",
                        label:
                          "Please select the events that this user should be notified for.",
                        "label-for": "companies",
                      },
                    },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { name: "check-button", switch: "" },
                          model: {
                            value:
                              _vm.datasource.item.notify_service_of_process,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.datasource.item,
                                "notify_service_of_process",
                                $$v
                              )
                            },
                            expression:
                              "datasource.item.notify_service_of_process",
                          },
                        },
                        [_vm._v("\n          Service of Process\n        ")]
                      ),
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { name: "check-button", switch: "" },
                          model: {
                            value: _vm.datasource.item.notify_documents,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.datasource.item,
                                "notify_documents",
                                $$v
                              )
                            },
                            expression: "datasource.item.notify_documents",
                          },
                        },
                        [_vm._v("\n          Documents\n        ")]
                      ),
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { name: "check-button", switch: "" },
                          model: {
                            value: _vm.datasource.item.notify_invoices,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.datasource.item,
                                "notify_invoices",
                                $$v
                              )
                            },
                            expression: "datasource.item.notify_invoices",
                          },
                        },
                        [_vm._v("\n          Invoices\n        ")]
                      ),
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { name: "check-button", switch: "" },
                          model: {
                            value: _vm.datasource.item.notify_compliance,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.datasource.item,
                                "notify_compliance",
                                $$v
                              )
                            },
                            expression: "datasource.item.notify_compliance",
                          },
                        },
                        [_vm._v("\n          Compliance\n        ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }