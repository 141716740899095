<template>
  <b-modal
    id="accessManagementModal"
    ref="accessManagementModal"
    :title="modalTitle"
    footer-class="border-top-0"
    size="lg"
    @ok="handleOk"
  >
    <b-form v-if="datasource && datasource.item" autocomplete="false" class="mb-0 pb-0">
      <b-card class="mb-4 pb-0">
        <template slot="header">
          Permissions
        </template>
        <b-form-group
          id="companiesLabel"
          label-for="companies"
          label="Please select the role that should be applied to this user."
        >
          <b-form-radio-group
            v-model.number="datasource.item.role"
            :options="roleOptions"
            name="radio-inline"
          />
        </b-form-group>
      </b-card>

      <b-card class="mb-4 pb-0">
        <span slot="header">Notifications</span>
        <b-form-group
          id="companiesLabel"
          label="Please select the events that this user should be notified for."
          label-for="companies"
        >
          <b-form-checkbox v-model="datasource.item.notify_service_of_process" name="check-button" switch>
            Service of Process
          </b-form-checkbox>
          <b-form-checkbox v-model="datasource.item.notify_documents" name="check-button" switch>
            Documents
          </b-form-checkbox>
          <b-form-checkbox v-model="datasource.item.notify_invoices" name="check-button" switch>
            Invoices
          </b-form-checkbox>
          <b-form-checkbox v-model="datasource.item.notify_compliance" name="check-button" switch>
            Compliance
          </b-form-checkbox>
        </b-form-group>
      </b-card>
    </b-form>
  </b-modal>
</template>

<script>
  import http from '../http'

  export default {
    name: 'NotificationsModal',
    props: {
      datasource: { type: Object, default: null },
    },
    computed: {
      modalTitle () {
        if (this.datasource.item) {
          const invitee = this.datasource.item.invitee
          const name = invitee.first_name + ' ' + invitee.last_name
          const company = this.datasource.item.company.name
          return `Modify ${name}'s Access to ${company}`
        }
      },

      roleOptions () {
        return {
          1: 'Owner',
          2: 'Associate',
          3: 'Professional',
        }
      },
    },
    watch: {},
    methods: {
      handleOk(bvModalEvt) {
        bvModalEvt.preventDefault()
        this.handleSubmit()
      },

      async handleSubmit () {
        try {
          await http.put(`client/sharing_access_rules/${this.datasource.item.id}`, {
            sharing_access_rule: this.datasource.item,
          })
          this.successToast()
          this.$nextTick(() => {
            this.$refs.accessManagementModal.hide()
          })
        } catch (error) {
          console.log(error)
          this.failToast()
        }
      },

      successToast () {
        this.$bvToast.toast('Record(s) Updated', {
          title: 'Success',
          autoHideDelay: 2000,
        })
      },

      failToast () {
        this.$bvToast.toast('There was a problem communicating with the server.', {
          title: 'Error',
          autoHideDelay: 2000,
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  h5 {
    font-size: 1.25rem;
  }

  @media only screen and (max-width: 550px) {
  }
</style>
