<template>
  <b-container>
    <masthead title="Shared Settings" :show-search="false" />

    <div class="container-fluid pt-4 ml-0 pl-0 pr-0">
      <b-tabs v-model="currentTab">
        <b-tab title="Shared with Others">
          <ajax-table ref="sharedWithOthersTable" :table-definition="tableSharedWithOthers">
            <template slot="cell(actions)" slot-scope="row">
              <b-button v-b-modal.accessManagementModal variant="link" class="ml-4 mr-4 pt-0 pb-0" @click="modalData=row">
                Manage
              </b-button>
              <b-button variant="link" class="ml-4 mr-4 pt-0 pb-0" style="color:red" @click="confirmRemove(row.item)">
                Remove
              </b-button>
            </template>
          </ajax-table>
        </b-tab>

        <b-tab title="Shared with You">
          <ajax-table ref="sharedWithYouTable" :table-definition="tableSharedWithYou">
            <template slot="cell(actions)" slot-scope="row">
              <b-button variant="link" class="ml-1 mr-1 pt-0 pb-0" style="color:red" @click="confirmRemove(row.item)">
                Leave
              </b-button>
            </template>
          </ajax-table>
        </b-tab>
      </b-tabs>
    </div>

    <access-management-modal :datasource="modalData" />
  </b-container>
</template>

<script>
  import FeatherIcon from '@/components/shared/FeatherIcon'
  import Masthead from '@/components/shared/Masthead'
  import CPagination from '../components/shared/Pagination'
  import AjaxTable from '../components/shared/AjaxTable'
  import http from '../http'
  import AccessManagementModal from '@/components/AccessManagementModal'

  import _ from 'lodash'

  export default {

    name: 'SharedSettings',
    components: { AjaxTable, CPagination, FeatherIcon, Masthead, AccessManagementModal },

    data () {
      return {
        modalData: {},
        query: '',
        appliedFilters: [],
        currentTab: null,
      }
    },

    computed: {

      roleOptions () {
        return {
          1: 'Owner',
          2: 'Associate',
          3: 'Professional',
        }
      },

      tableSharedWithOthers () {
        return {
          columns: [
            { key: 'company.name', label: 'Company' },
            { key: 'invitee_email', label: 'Email' },
            { key: 'role', label: 'Role', formatter: value => this.roleOptions[value] },
            { key: 'actions', label: '' },
          ],
          url: 'client/sharing_access_rules/owned_by_me',
          defaultOrderBy: 'created_at',
        }
      },

      tableSharedWithYou () {
        return {
          columns: [
            { key: 'company.name', label: 'Company' },
            {
              key: 'invitee', label: 'Shared With',
              formatter: invitee => _.startCase(`${invitee.first_name} ${invitee.last_name}`),
            },
            { key: 'invitee_email', label: 'Email' },
            { key: 'actions', label: 'Actions' },
          ],
          url: 'client/sharing_access_rules/owned_by_others',
          defaultOrderBy: 'created_at',
        }
      },
    },

    methods: {
      confirmRemove (item) {
        const modalOptions = {
          title: 'Confirmation',
          buttonSize: 'sm',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          okTitle: 'Remove',
        }

        const message = this.currentTab === 0
          ? `Are you sure you want to remove ${item.invitee.first_name} ${item.invitee.last_name} from ${item.company.name}?`
          : `Are you sure you want to remove yourself from ${item.company.name}?`

        this.$bvModal
          .msgBoxConfirm(message, modalOptions)
          .then(value => { if (value) { this.removeRule(item) } })
      },

      async removeRule (item) {
        await http.delete(`client/sharing_access_rules/${item.id}`)
        this.$refs.sharedWithOthersTable.reload()
        this.$refs.sharedWithYouTable.reload()
      },
    },
  }
</script>

<style lang="scss" scoped>
  h2 {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media only screen and (max-width: 550px) {
  }
</style>
